import React from "react";
import { DiReact, DiNodejs, DiGit, DiDocker, DiHtml5, DiPython } from "react-icons/di";
import { SiTensorflow , SiTypescript , SiGooglecloud , SiAmazonaws , SiRedis , SiJavascript } from "react-icons/si";
import { SiDotnet } from "react-icons/si";
import { DiCss3 } from "react-icons/di";
import { TbBrandNextjs } from "react-icons/tb";
import "./experience.css";

const Experience = () => {
  return (
    <section id="experience">
      {/* <h5>The Skills I Have</h5> */}
      <h2>Skills</h2>
      <div className="container experience__container">
        
        <div className="experience__row">
          <h3>Languages</h3>
          <div className="experience__content">
            {/* Add programming languages here */}
            <SiJavascript className="experience__details-icon" />
            <SiTypescript className="experience__details-icon" />
            <DiHtml5 className="experience__details-icon" />
            <DiCss3 className="experience__details-icon" />
            <DiPython className="experience__details-icon" />
            <SiDotnet className="experience__details-icon" />
            {/* ... add more programming languages */}
          </div>
        </div>

        <div className="experience__row">
          <h3>Tools & Frameworks</h3>
          <div className="experience__content">
            {/* Add tools and frameworks here */}
            <DiReact className="experience__details-icon" />
            <DiNodejs className="experience__details-icon" />
            <SiGooglecloud className="experience__details-icon" />
            <SiAmazonaws className="experience__details-icon" />
            <DiGit className="experience__details-icon" />
            <DiDocker className="experience__details-icon" />
            <SiTensorflow className="experience__details-icon" />
            <SiRedis className="experience__details-icon" />
            <TbBrandNextjs className="experience__details-icon" />
            {/* ... add more tools and frameworks */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
