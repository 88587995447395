import "./intro.css";

import { FaAward } from "react-icons/fa";
import React from "react";
import { VscFolderLibrary } from "react-icons/vsc";
import img from "../../assets/prof_pic_2.jpg";

const Intro = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={img} alt="Don Kaluarachchi" />
          </div>
        </div>
        <div className="about__content">
          {/* <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>5+ year</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>32+ Completed Projects</small>
            </article>
          </div> */}
          <p>
          I am an ambitious and innovative professional with a passion for robotics, software engineering, and full-stack development. As a curious and lifelong learner, I continuously seek opportunities to acquire new skills and expand my knowledge in these exciting fields.
            <br />
            <br />
            Driven by my innate love for problem-solving, I thrive in challenging environments where I can leverage technology to create impactful solutions. Whether it's developing cutting-edge software applications or designing intricate robotic systems, I am dedicated to pushing the boundaries of what is possible.
            <br />
            <br />
            Beyond my academic pursuits, I actively engage in side projects that allow me to apply my skills and explore new avenues of innovation. From web and app development to embracing martial arts as a means of discipline and personal growth, I thrive on the thrill of pushing my abilities to the limit.
            <br />
            <br />
            Volunteering and participating in various programs, such as Big Talk, Engineers Without Borders and Leadership and Development initiatives, are integral parts of my journey. By actively contributing to my community and fostering a spirit of collaboration, I aim to make a meaningful impact on the world around me.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;
