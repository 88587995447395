import "./portfolio.css";

import IMG1 from "../../assets/SiteSeeDemo.png";
import IMG2 from "../../assets/sitesee-mc.jpg";
import IMG3 from "../../assets/collisorpay.png";
import IMG4 from "../../assets/drone-against-malaria.png";
import IMG5 from "../../assets/semantic-seg.png";
import IMG6 from "../../assets/VRES_Games.png";
import React from "react";

//Portfolio function
const Portfolio = () => {
  const soloProjects = [
    {
      id: 1,
      title: "SiteSee",
      img: IMG1,
      description:
        "SiteSee is a infrastructure analysis service that delivers asset intelligence. SiteSee's 3D modeling & analytics SaaS bridges the gap between design and the real world, to empower infrastructure owners to make informed decisions with real-world data.",
      technologies: "TypeScript | Express.js | React.js | Azure | CosmosDB",
      link: "https://www.sitesee.io/",
      buttontext : "Visit Website",
    },
    {
      id: 2,
      title: "MissionControl & Drone Systems",
      img: IMG2,
      description:
        "MissionControl is a Ground Control Station that allows to control the Drone remotely and upload flight plans to perform SiteSee's Automatic Image Acquisition. The application was deployed to the AppStore for use by SiteSee pilots and customers, showcasing expertise in mobile app development.",
      technologies: "iOS | Swift UI | GroundSDK",
      link: "https://www.sitesee.io/",
      buttontext : "Visit Website",
    },
    {
      id: 3,
      title: "CollisorPay",
      img: IMG3,
      description:
        "A FinTech startup that connects student with educational institutes and provides a bun now, pay later (BNPL) service for students to undertake coures without financial burden.",
      technologies: "Javascript | React | PHP | SQL | CodeIgntitor | Stripe and Twilio API's",
      link: "https://github.com/DonMMK/CollisorPay",
      buttontext : "See on Github",
    },
    {
      id: 4,
      title: "Drones against Malaria",
      img: IMG4,
      description:
        "Thesis project that aims to address these challenges by developing a comprehensive strategy that utilizes drone technologies for detection and spot spray application",
      technologies: "QGIS | Python | DJI Drones (M3E, M300 with Altum PT & Agras T10)",
      link: "https://public-stored-items.s3-ap-southeast-2.amazonaws.com/EGH408_Thesis_Final_Compressed_Report.pdf",
      buttontext : "View Thesis Report", 
    },
    {
      id: 5,
      title: "Aerial Semantic Segmentation",
      img: IMG5,
      description:
        "Training and testing CNNs to perform segmentation on aerial data that can be used by drones for landing during an automated 'Return to Home' process.",
      technologies: "Python | TensorFlow | Keras | OpenCV | Pandas | Google Colab",
      link: "https://github.com/DonMMK/aerial-semantic-segmentation",
      buttontext : "See on Github",
    },
    {
      id: 6,
      title: "Undergraduate VRES scholarship project",
      img: IMG6,
      description:
        "Implemented games and delivered a compelling research pitch on 'Data Collection and Game Development: Exploring the Effects of Video Games on Mood' under the guidance of Dr. Johnson, leader of the QUT Games Research and Interaction Design (GRID) lab.",
      technologies: ".NET | C# | Unity",
      link: "https://github.com/DonMMK/VRES-Games-on-Unity",
      buttontext : "See on Github",
    },
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3>{pro.title}</h3>
              <p>{pro.description}</p>
              <p>{pro.technologies}</p>
            </div>
            <div className="portfolio__item-cta">
              <a
                href={pro.link}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                {pro.buttontext}
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
